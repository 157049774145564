import { Controller } from 'stimulus';
import latinize from 'latinize';
import { productHeader } from '../shared/product_header';
import PerfectScrollbar from 'perfect-scrollbar';
import { flashMessage } from '../shared/notify';
export default class ShopController extends Controller {

  connect() {
    $('.loading_app_container').addClass('d-none')
    localStorage.setItem('currentUrl', window.location)
    const pSBC=(p,c0,c1,l)=>{
      let r,g,b,P,f,t,h,m=Math.round,a=typeof(c1)=="string";
      if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
      h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBC.pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBC.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
      if(!f||!t)return null;
      if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
      else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
      a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
      if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
      else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
    }

    pSBC.pSBCr=(d)=>{
      const i=parseInt;
      let n=d.length,x={};
      if(n>9){
        const [r, g, b, a] = (d = d.split(','));
              n = d.length;
        if(n<3||n>4)return null;
        x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
      }else{
        if(n==8||n==6||n<4)return null;
        if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
        d=i(d.slice(1),16);
        if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=Math.round((d&255)/0.255)/1000;
        else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
      }return x
    }; 

    var c = $(this.element).data().color.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >>  8) & 0xff;  // extract green
    var b = (rgb >>  0) & 0xff;  // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709    

    let factor = luma > 128 ? -0.2 : 0.2
    document.querySelector(':root').style.setProperty('--Shop-Color', $(this.element).data().color);
    document.querySelector(':root').style.setProperty('--Shop-Color-Rgb', this.hexToRgb($(this.element).data().color));
    document.querySelector(':root').style.setProperty('--Shop--Hover--Color', pSBC(factor, $(this.element).data().color));
    if($(this.element).data().url.length) {
      $('#checkout_modal').modal('show')
      $.ajax({url: $(this.element).data().url, dataType: 'script'});
    }
    if(localStorage.getItem('edit_product')) {
      setTimeout(() => {
        try {
          let item = JSON.parse(localStorage.getItem('edit_product'))
          localStorage.removeItem('edit_product')
          productHeader($(`[data-item-id="${item.smartId}"]`))
          $.get(`/basket_products/${item.cartId}/edit`)
        } catch (error) { }
      }, 500)

    }

    /**
    $.each($('.shop_show_items_container'), (i, e) => {
      new PerfectScrollbar($(e).get(0))
    })
    
    **/
    //this.category_scroll = new PerfectScrollbar($('#shop-show__sticky__categories').get(0))
    this.initScroll()


    if($('#mobile-shop-show').data().product && $(`#shop_product_${$('#mobile-shop-show').data().product}`)) {
      let product = $(`#shop_product_${$('#mobile-shop-show').data().product}`)
      productHeader(product)
      $.get(`/${product.data().type}/new?item_id=${product.data().itemId}`)
    }
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
  }

  initScroll() {
    const el = document.querySelector("#shop-show__sticky")
    const observer = new IntersectionObserver( 
      ([e]) => {
        e.target.classList.toggle("shop-show__sticky--pinned", e.intersectionRatio < 1)
      },
      { threshold: [1] }
    );

    observer.observe(el);    
    this.first_visible = undefined

    $(document).on('scroll', () => {
      if($('.fixed_shop_category').length > 0) {
        return
      }
      $('.shop-show__categories__item').removeClass('active')
      if($('#shop-show__header').innerHeight() < $(document).scrollTop()) {
        $.each($('.shop-show__section--category'), (index, element) => {
          if($(element).offset().top > $(document).scrollTop()) {
            this.first_visible = $(element)
            return false
          }
        })
        let prev = $(`#category_header_${this.first_visible.data().category}`).prevAll().map((e, i) => {return $(i).innerWidth()})
        let offset = prev.get().reduce((partialSum, a) => partialSum + a, 0) + $('.search_shop_container').innerWidth()
        $(`#category_header_${this.first_visible.data().category}`).addClass('active')
        $('#shop-show__sticky__categories').animate({
          scrollLeft: offset + (prev.length * 4) - 8
        }, 0);
        //this.category_scroll.update()
      } else {
        $('.shop-show__categories__item').eq(0).addClass('active')

        $('#shop-show__sticky__categories').animate({
          scrollLeft: 0
        }, 0);
        //this.category_scroll.update()
      }
      $('')

    })
  }

  show_all() {
    $('.shop-show__thumbnail-line').show()
    $('.shop-show__categories__item').removeClass('active')
    $('.shop_show_item_card').show()
    $(`.shop-show__categories__item[data-category-id="all"]`).addClass('active')
    $('.shop-show__section').toggle(true)
    $('.fixed_shop_category').removeClass('fixed_shop_category')
    $('.shop-show__section__items-container').removeClass('wrapping')
  }

  select_category(e) {
    let id = $(e.currentTarget).data().categoryId
    this.show_all()

    $('#shop-show_search_input').val('')
    $('hr.shop-show__horizontal-separation').show()
    $('.shop-show__thumbnail-line').show()
    $('.shop_shopw_section__see-all').show()
    if(id != 'all') {
      $('.shop-show__section').hide()
      $(e.currentTarget).addClass('fixed_shop_category')
      $('hr.shop-show__horizontal-separation').hide()
      $('.shop-show__thumbnail-line').hide()
      $('.shop_shopw_section__see-all').hide()
      let section = $(`#shop_section_${id}`)
      section.show()
      section.get(0).scrollIntoView();
      section.find('.shop_show_item_card').show()
      section.find('.shop-show__section__items-container').addClass('wrapping')
    } else {
      this.show_all()
    }
    $('.shop-show__categories__item').removeClass('active')
    $(`.shop-show__categories__item[data-category-id="${id}"]`).addClass('active')
  }


  search(e) {
    $('.shop-show__section').show()
    let value = latinize($(e.currentTarget).val().toLowerCase())
    if(value.length > 0) {
      $('.shop-show__thumbnail-line').hide()
      // window.scrollTo(0, 0);
      $('.shop_show_item_card').filter(function() {
        return $(this).toggle(latinize($(this).data().filter.toLowerCase()).indexOf(value) > -1)
      });
    } else {
      this.show_all()
    }

    $('.shop-show__section').filter(function() {
      $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
    })

    $('.shop-show__section--category:visible').get(0).scrollIntoView()

  }

  blursearch(e) {
    if($(e.currentTarget).val().length == 0) {
      $('#shop-show__sticky__search-container').removeClass('shop-show__sticky__search-container--opened')
    }
  }

  toggleSearch() {
    if($('#shop-show__sticky__search-container').hasClass('shop-show__sticky__search-container--opened')) {
      $('#shop-show__sticky__search-container').removeClass('shop-show__sticky__search-container--opened')
      $('#shop-show__sticky__search-container input').val('')
      this.show_all()
    } else {
      $('#shop-show__sticky__search-container').addClass('shop-show__sticky__search-container--opened')
      $('#shop-show__sticky__search-container input').focus()
    }
    
  }

  showHours() {
    let url = `/shops/${$(this.element).data().id}/show_hours`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }

  showDescription() {
    let url = `/shops/${$(this.element).data().id}/show_description`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }  

  showVitrine(e) {
    let url = `/shops/${$(this.element).data().id}/show_vitrine?vitrine_id=${$(e.currentTarget).data().id}`
    if($(this.element).data().type.length) {
      url += `&shop_type=true`
    }
    $.get(url)    
  }

  showAddress(e) {
    let url = `/shops/${$(this.element).data().id}/show_address`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)     
  }

}
